import styled from 'styled-components'
import media from 'styled-media-query'

export default styled.section`
  height: 100vh;
  width: 100%;
  position: relative;

  ${
    media.lessThan('849px')`
      height: 100%;
    `
  }
`

import React from 'react'
import styled from 'styled-components'
import { useMediaQuery, } from 'react-responsive'

import { SectionCommonProps, } from 'pages/Landing/types'

import { Span, Container, utils, } from 'styles'

import colors from 'globals/colors'

interface Props extends SectionCommonProps {

}

const FooterContainer = styled.footer`
  padding-top: 3em;
  padding-bottom: 4.3em;
  background-color: rgb(248, 248, 248);
  ${ utils.flex('row', 'center') }

  .content {
    margin-top: 1.5em;
    ${ utils.flex('row', 'center', 'center') }
  }
`

function LandingFooter(props: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 849px)' })

  return (
    <FooterContainer { ... props }>
      <Container>
        <div className='content'>
          <Span size={ 1.5 } weight='extrabold' sizeUnit='em' color='rgb(39, 39, 39)'>
            D405 HOUSE
          </Span>
        </div>
        <div className='content'>
          {
            isMobile ? (
              <>
                <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.3 }>
                  대표: 문재영
                </Span>
                <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)'>
                  사업자등록번호: 449-81-02181
                </Span>
              </>
            ) : (
              <>
                <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.3 }>
                  대표: 문재영
                </Span>
                <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.3 }>
                  주소: 인천광역시 연수구 송도과학로70 송도에이티센터 오피스 2213호
                </Span>
              </>
            )
          }
        </div>
        {
          isMobile ? (
            <div className='content'>
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)'>
                주소: 인천광역시 연수구 송도과학로70 송도에이티센터 오피스 2213호
              </Span>
            </div>
          ) : null
        }
        {
          isMobile ? (
            <div className='content'>
              {
                /*
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.5 }>
                Tel: 010-9725-0905
              </Span>
                 */
              }
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)'>
                Email: contact.us@d405.io
              </Span>
            </div>
          ) : (
            <div className='content'>
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.5 }>
                사업자등록번호: 449-81-02181
              </Span>
              {
                /*
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)' marginRight={ 1.5 }>
                Tel: 010-9725-0905
              </Span>
                 */
              }
              <Span size={ 1.1 } sizeUnit='em' color='rgb(39, 39, 39)'>
                Email: contact.us@d405.io
              </Span>
            </div>
          )
        }
        <div className='content copyright'>
          <div>
            Copyright 2020. D405 HOUSE Corp. all rights reserved.
          </div>
        </div>
      </Container>
    </FooterContainer>
  )
}

export default LandingFooter

import styled from 'styled-components'
import media from 'styled-media-query'
import Scrollspy from 'react-scrollspy'
import React, { useState, } from 'react'

import { LandingHeader, LandingFooter, } from 'systems'

import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'

const LandingContainer = styled.div`
  font-size: 16px;

  ${
    media.lessThan('1280px')`
      font-size: 14px;
    `
  }

  ${
    media.lessThan('849px')`
      font-size: 12px;
    `
  }
`

function Landing() {
  const [ focusedElementId, setFocusedElementId, ] = useState('section1')

  const onScrollUpdate = (element: HTMLElement) => {
    if(element) {
      setFocusedElementId(element.id)
    }
  }

  return (
    <LandingContainer>
      <LandingHeader />
      <Scrollspy
        currentClassName='current-section'
        items={[ 'about', 'service', 'portfolio', 'team', 'contactus' ]}
        onUpdate={ onScrollUpdate }
        componentTag='main'>
        <Section1 id='about' focused={ focusedElementId === 'about' } />
        <Section2 id='service' focused={ focusedElementId === 'service' } />
        <Section3 id='portfolio' focused={ focusedElementId === 'portfolio' } />
        <Section4 id='team' focused={ focusedElementId === 'team' } />
      </Scrollspy>
      <LandingFooter id='contactus' focused={ focusedElementId === 'contactus' } />
    </LandingContainer>
  )
}

export default Landing

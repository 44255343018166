import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useMediaQuery, } from 'react-responsive'

import { MemberCard, } from 'components'

import { P, H1, Span, utils, } from 'styles'

import colors from 'globals/colors'

import { SectionCommonProps, } from '../types'

interface Props extends SectionCommonProps {
}

const Section4Container = styled.div`
  padding: 8.625em 0;
  ${ utils.flex('column', 'center', 'center') }

  .member-card-container {
    margin-top: 3.25em;
    ${ utils.flex('row') }
    ${
      media.lessThan('700px')`
        ${ utils.flex('column', 'center', 'center') }
      `
    }
  }
`

function Section4(props: Props) {
  const isSmall = useMediaQuery({ query: '(max-width: 700px)', })

  return (
    <Section4Container { ... props }>
      <H1
        size={ 2.5 }
        sizeUnit='em'
        color='#757575'
        weight='bold'
        lineHeight={ 1.2 }
        textAlign={ isSmall ? 'center' : 'left' }>
        {
          isSmall ? (
            <>
              Core Members of<br />D405 HOUSE
            </>
          ) : (
            <>
              Core Members of D405 HOUSE
            </>
          )
        }
      </H1>
      <P
        size={ 1.5625 }
        sizeUnit='em'
        color='#757575'
        weight='light'
        lineHeight={ 1.4 }
        marginTop={ isSmall ? 1 : 0.6 }
        textAlign={ isSmall ? 'center' : 'left' }>
        {
          isSmall ? (
            <>
              시제품 개발부터 글로벌 서비스까지<br />도와드리는 전문적이고 젊은 감각의 팀`
            </>
          ) : `시제품 개발부터 글로벌 서비스까지 도와드리는 전문적이면서도 젊은 감각의 팀`
        }
      </P>
      <div className='member-card-container'>
        <MemberCard
          name='Jae Young'
          profileImage={ require('./assets/images/x86kernel.png') }
          resumes={[
            '소프트웨어 마에스트로 9기',
            '차세대보안리더 양성 프로그램\n(Best of the Best) 3기',
            '국군사이버사령부 (ROKCC)\n소프트웨어 개발병 전역',
          ]}
          githubURL='https://github.com/x86kernel'
          sizeUnit='em'
          marginRight={ isSmall ? 0 : 3 }
          marginBottom={ isSmall ? 3 : 0 }
        />
        <MemberCard
          name='Alice'
          profileImage={ require('./assets/images/alicetsang21.png') }
          resumes={[
            '4개 국어\n(광동어/중국어/영어/한국어)',
            '다수의 스타트업 기획 및\nUI/UX 디자인 경험',
            '고려대학교 국제학 학사',
          ]}
        />
      </div>
    </Section4Container>
  )
}

export default Section4

export const chunk = <T>(arr: Array<T>, size: number, fillDummy: boolean = false) => {
  const result = []

  for(let i = 0; i < arr.length; i += size) {
    let chunk = arr.slice(i, i + size)

    if(fillDummy && chunk.length < size) {
      chunk = chunk.concat(Array(size - chunk.length).fill(null))
    }

    result.push(chunk)
  }

  return result
}

import Typist from 'react-typist'
import styled from 'styled-components'
import media from 'styled-media-query'
import React, { useState, } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useMediaQuery } from 'react-responsive'

import { FullScreenSection, Overlay, H1, Span, P, utils, } from 'styles'

import colors from 'globals/colors'

import 'react-typist/dist/Typist.css'

import { SectionCommonProps, } from '../types'

const Section1Container = styled.div`
  position: relative;
  ${ utils.wh100 }
  ${
    media.lessThan('849px')`
      height: 80vh !important;
    `
  }

  .first-section__content {
    ${ utils.absolutePosition }
    ${ utils.flex('column', 'center', 'center') }
    ${ utils.wh100 }
  }

  .background {
    ${ utils.wh100 }

    video {
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .business-introduction-paper {
    width: 160px;
    height: 60px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    border: 0;
    cursor: pointer;
    background: linear-gradient(to left, #1A2980, #26D9CE);
    margin-top: 32px;

    ${
      media.lessThan('849px')`
        width: 120px;
        height: 40px;
        border-radius: 20px;
        font-size: 13px;
        margin-top: 16px;
      `
    }
  }
`

const TypingDescription = styled(Typist)`
  ${ utils.flex('row', 'center', 'center') }
  color: white;
  font-size: 1.8em;
  font-weight: 100;
`

function Section1(props: SectionCommonProps) {
  const [ isTypingDone, setTypingDone, ] = useState(false)

  const isSmall = useMediaQuery({ query: '(max-width: 849px)' })

  const onTypingDone = () => {
    setTypingDone(true)
  }

  const onBusinessIntroductionDownload = () => {
    window.open('/static/pdfs/회사소개서.pdf', '_blank')

    ReactPixel.trackCustom('view_business_introduction_click')
  }

  return (
    <FullScreenSection { ... props }>
      <Section1Container>
        <div className='background'>
          <video
            // @ts-ignore
            playsInline={ true }
            autoPlay={ true }
            loop={ true }
            src={ require('./assets/videos/background.mp4') }
            muted={ true }
          />
          <Overlay opacity={ 0.6 } />
        </div>
        <div className='first-section__content'>
          <H1 color='white' size={ isSmall ? 3 : 4.5 } sizeUnit='em' weight='bold' marginBottom={ 0.3 }>
            D405 HOUSE
          </H1>
          <TypingDescription onTypingDone={ onTypingDone }>
            <P size={ isSmall ? 0.7 : 1.1 } sizeUnit='em'>
              저희는 개발사가 아닌 당신과 함께 가는 개발팀입니다.
            </P>
          </TypingDescription>
          {
            isTypingDone && (
              <button
                onClick={ onBusinessIntroductionDownload }
                className='business-introduction-paper'>
                회사소개서 보기
              </button>
            )
          }
        </div>
      </Section1Container>
    </FullScreenSection>
  )
}

export default Section1

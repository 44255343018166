import styled from 'styled-components'
import media from 'styled-media-query'

interface ContainerProps {
  size?: 'small' | 'medium'
}

export default styled.div<ContainerProps>`
  width: ${ (props: ContainerProps) => props.size === 'medium' ? '1100px' : '1000px' };

  ${
    media.lessThan('1050px')`
      width: auto;
    `
  }
`

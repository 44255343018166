import styled from 'styled-components'

import { CommonTextProps, commonTextStyle, } from '../utils'

interface AStyleProps extends CommonTextProps {
  hoverColor?: string
  onClick?: any
}

export default styled.a<AStyleProps>`
  ${ (props: AStyleProps) => commonTextStyle(props) }
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  ${
    (props: AStyleProps) => {
      if(props.hoverColor) {
        return `
          &:hover {
            color: ${ props.hoverColor };
          }
          transition: color 0.3s ease !important;
        `
      }
    }
  }
`

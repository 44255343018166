import styled from 'styled-components'

interface OverlayProps {
  opacity: number
}

export default styled.div<OverlayProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, ${ (props: OverlayProps) => props.opacity });
`

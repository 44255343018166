import ReactGA from 'react-ga4'
import React, { useEffect, } from 'react'

import { Landing, } from 'pages'

function App() {
  useEffect(
    () => {
      try {
        ReactGA.initialize('G-NN618WCZS6')
      } catch (error) {
        console.dir(error)
      }
    },
    []
  )

  return (
    <Landing />
  )
}

export default App

import styled from 'styled-components'
import media from 'styled-media-query'
import ReactPixel from 'react-facebook-pixel'
import React, { useRef, useState, } from 'react'
import { useMediaQuery } from 'react-responsive'
import ImageViewer from 'react-simple-image-viewer'

import { PortfolioCard, } from 'components'
import { PortfolioModal, PortfolioModalRef, } from 'systems'

import { H1, Container, utils, } from 'styles'

import { chunk, } from 'utils/helpers'

import colors from 'globals/colors'
import { Portfolio, } from 'globals/types'

import { SectionCommonProps } from '../types'

interface Props extends SectionCommonProps {

}

const StyledPortfolioCard = styled(PortfolioCard)``

const Section3Container = styled.div`
  padding-top: 14.1875em;
  padding-bottom: 12.4375em;
  background-color: #FAFAFA;
  ${ utils.flex('column', undefined, 'center') }

  ${ Container } {
    ${
      media.lessThan('849px')`
        width: 95%;
      `
    }
  }

  .title-container {
    ${ utils.flex('column') }

    .title-container__d405 {
      align-self: flex-start;
      background: linear-gradient(to left, #1A2980, #26D9CE);
    }
  }

  .portfolios-row {
    margin-top: 5.8125em;
    ${ utils.flex('row', 'space-between') }

    ${ StyledPortfolioCard } {
      width: 33%;
      ${
        media.lessThan('849px')`
          width: 49%;
        `
      }

    }
  }
`

const PORTFOLIOS: Array<Portfolio> = [
  {
    name: '마녀의 빗자루',
    description: '청소 중개 웹 서비스',
    thumbnailImage: require('./assets/images/mabit.png'),
  },
  {
    name: 'Fasket',
    description: '우리 동네 배달 마켓 앱 서비스',
    thumbnailImage: require('./assets/images/fasket.png'),
  },
  {
    name: 'Hey! Mirror',
    description: '모바일 데이팅 서비스',
    thumbnailImage: require('./assets/images/hey_mirror.png'),
  },
  {
    name: '폐업119',
    description: '폐업을 위한 올인원 앱 플랫폼',
    thumbnailImage: require('./assets/images/close119.png'),
  },
  {
    name: '블리스 다이어트',
    description: '오프라인 피트니스 센터 회원관리 앱',
    thumbnailImage: require('./assets/images/blissdiet.png'),
  },
  {
    name: 'Tadoo',
    description: '오픈마켓 형태의 재능 거래 모바일 플랫폼',
    thumbnailImage: require('./assets/images/tadoo.png'),
  },
  {
    name: 'HKTimes',
    description: '홍콩 최신 뉴스 기반 커뮤니티 앱',
    thumbnailImage: require('./assets/images/hktimes.png'),
  },
  {
    name: 'GnB Book Pi',
    description: '영어학습을 위한 E-Book 앱',
    thumbnailImage: require('./assets/images/gnbbookpi.png'),
  },
  {
    name: 'Plezus',
    description: '게이머를 위한 SNS',
    thumbnailImage: require('./assets/images/plezus.png'),
  },
  /*
  {
    name: 'YOLotto',
    description: '블록체인 기반 로또 및 ICO 시스템',
    thumbnailImage: require('./assets/images/yolotto.png'),
  },
 */
  {
    name: '러브블라썸',
    description: '결혼/연애 만남을 위한 웹 플랫폼',
    thumbnailImage: require('./assets/images/loveblossom.jpg'),
  },
]

const PORTFOLIOS_MOBILE: Array<Portfolio> = [
  {
    name: '마녀의 빗자루',
    description: '청소 중개 웹 서비스',
    thumbnailImage: require('./assets/images/mabit.png'),
  },
  {
    name: 'Fasket',
    description: '우리 동네 배달 마켓 앱 서비스',
    thumbnailImage: require('./assets/images/fasket.png'),
  },
  {
    name: 'Hey! Mirror',
    description: '모바일 데이팅 서비스',
    thumbnailImage: require('./assets/images/hey_mirror.png'),
  },
  {
    name: '폐업119',
    description: '폐업을 위한 올인원 앱 플랫폼',
    thumbnailImage: require('./assets/images/close119.png'),
  },
  {
    name: '블리스 다이어트',
    description: '오프라인 피트니스 센터 회원관리 앱',
    thumbnailImage: require('./assets/images/blissdiet.png'),
  },
  {
    name: 'Tadoo',
    description: '오픈마켓 형태의 재능 거래 모바일 플랫폼',
    thumbnailImage: require('./assets/images/tadoo.png'),
  },
  {
    name: 'HKTimes',
    description: '홍콩 최신 뉴스 기반 커뮤니티 앱',
    thumbnailImage: require('./assets/images/hktimes.png'),
  },
  {
    name: 'GnB Book Pi',
    description: '영어학습을 위한 E-Book 앱',
    thumbnailImage: require('./assets/images/gnbbookpi.png'),
  },
  {
    name: 'Plezus',
    description: '게이머를 위한 SNS',
    thumbnailImage: require('./assets/images/plezus.png'),
  },
  /*
  {
    name: 'YOLotto',
    description: '블록체인 기반 로또 및 ICO 시스템',
    thumbnailImage: require('./assets/images/yolotto.png'),
  },
   */
]

function Section3(props: Props) {
  const [ isImageViewerOpen, setImageViewerOpen, ] = useState(false)
  const [ imageViewerIndex, setImageViewerIndex, ] = useState(0)

  const portfolioModal = useRef<PortfolioModalRef>()

  const isSmall = useMediaQuery({ query: '(max-width: 849px)', })

  const onPortfolioCardClick = (portfolio: Portfolio, index: number) => {
    return () => {
      setImageViewerIndex(index)
      setImageViewerOpen(true)

      ReactPixel.trackCustom('view_portfolio_click', {
        name: portfolio.name,
      })
    }
  }

  return (
    <Section3Container { ... props }>
      {
        isImageViewerOpen && (
          <ImageViewer
            disableScroll={ true }
            currentIndex={ imageViewerIndex }
            closeOnClickOutside={ true }
            onClose={ () => setImageViewerOpen(false) }
            src={
              (isSmall ? PORTFOLIOS_MOBILE : PORTFOLIOS).map(portfolio => portfolio.thumbnailImage)
            }
          />
        )
      }
      <PortfolioModal ref={ portfolioModal } />
      <Container>
        <div className='title-container'>
          <div className='title-container__d405'>
            <H1
              size={ 3.7125 }
              sizeUnit='em'
              color='white'
              weight='extrabold'>
              D405
            </H1>
          </div>
          <H1 size={ 3.4 } sizeUnit='em' color='#393939' weight='extrabold' marginTop={ 0.1 }>
            PRODUCTION LIBRARY
          </H1>
        </div>
        {
          chunk(isSmall ? PORTFOLIOS_MOBILE : PORTFOLIOS, isSmall ? 2 : 3, true).map((chunkedPortfolios, index) => {
            return (
              <div className='portfolios-row'>
                {
                  chunkedPortfolios.map((portfolio, innerIndex) => {
                    if(!portfolio) {
                      return (
                        <div style={{ width: '33%', }} />
                      )
                    }

                    return (
                      <StyledPortfolioCard
                        key={ `portfolio-${ index }-${ innerIndex }` }
                        portfolio={ portfolio }
                        onClick={ onPortfolioCardClick(portfolio, (index * (isSmall ? 2 : 3)) + innerIndex ) }
                      />
                    )
                  })
                }
              </div>
            )
          })
        }
      </Container>
    </Section3Container>
  )
}

export default Section3

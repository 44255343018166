import React from 'react'
import styled from 'styled-components'
import ReactPixel from 'react-facebook-pixel'
import { useMediaQuery, } from 'react-responsive'

import { Img, H1, A, P, utils, } from 'styles'
import { CommonMarginProps, } from 'styles/utils'

import colors from 'globals/colors'

interface Props extends CommonMarginProps {
  name: string
  profileImage: any
  resumes: Array<string>
  githubURL?: string
}

const MemberCardContainer = styled.div`
  width: 26.25em;
  height: 16.6875em;
  background-color: #242424;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  ${ utils.flex('row') }
  ${ utils.commonMarginStyle }

  .member-profile-container {
    padding-top: 1.3125em;
    padding-left: 1.3125em;
    padding-bottom: 0.6943em;
    padding-right: 0.61875em;
    ${ utils.flex('column') }
    flex: 1;

    .member-profile__resumes {
      margin-top: 0.6875em;

      p {
        white-space: break-spaces;
      }
    }

    .member-profile__links {
      flex: 1;
      ${ utils.flex('column', 'flex-end', 'flex-end') }
    }
  }
`

function MemberCard(props: Props) {
  const { name, resumes, profileImage, githubURL, ...marginProps } = props

  const isSmall = useMediaQuery({ query: '(max-width: 849px)', })

  const onGithubLinkClick = () => {
    window.open(githubURL, '_blank')

    ReactPixel.trackCustom('view_github_profile', {
      url: githubURL,
    })
  }

  return (
    <MemberCardContainer { ... marginProps }>
      <Img
        width={ 11.8125 }
        sizeUnit='em'
        src={ profileImage }
        objectFit='cover'
      />
      <div className='member-profile-container'>
        <H1 size={ 1.75 } sizeUnit='em' color={ colors.PRIMARY }>
          { name }
        </H1>
        <div className='member-profile__resumes'>
          {
            resumes.map((resume, index: number) => {
              return (
                <P
                  size={ 0.75 }
                  sizeUnit='em'
                  lineHeight={ isSmall ? 2 : 1.5875 }
                  weight='light'
                  marginTop={ index > 0 ? 0.6125 : 0 }
                  color='white'>
                  { resume }
                </P>
              )
            })
          }
        </div>
        <div className='member-profile__links'>
          {
            githubURL ? (
              <A onClick={ onGithubLinkClick }>
                <Img
                  src={ require('./assets/images/github.svg') }
                />
              </A>
            ) : null
          }
        </div>
      </div>
    </MemberCardContainer>
  )
}

export default MemberCard

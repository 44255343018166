import styled from 'styled-components'
import media from 'styled-media-query'
import ReactPixel from 'react-facebook-pixel'
import { useMediaQuery }  from 'react-responsive'
import { slide as Menu, } from 'react-burger-menu'
import { Transition, } from 'react-transition-group'
import React, { useState, useMemo, useEffect, } from 'react'

import { I, A, Span, utils, } from 'styles'

import colors from 'globals/colors'

interface Props {
  fixed?: boolean
}

interface LandingHeaderProps {
  fixed: boolean
  state?: string
}

const Header = styled.header<LandingHeaderProps>`
  width: 100%;
  height: 80px;
  z-index: 10;
  top: 0;
  left: 0;
  position: absolute;
  ${ utils.flex('row', 'center', 'center') }
  ${
    media.lessThan('849px')`
    `
  }

  ${
    (props: LandingHeaderProps) => {
      switch(props.state) {
        case 'entering':
          return `
            position: fixed;
            opacity: 0;
          `
        case 'entered':
          return `
            position: fixed;
            opacity: 1;
            transition: 0.5s;
            background-color: rgba(0, 0, 0, 0.9);
          `
      }
    }
  }
`

const HeaderContent = styled.div`
  width: 1024px;
  ${ utils.flex('row', 'center', 'center') }

  ${
    media.lessThan('1280px')`
      width: 800px;
    `
  }

  ${
    media.lessThan('849px')`
      width: 90%;
      ${ utils.flex('row', 'space-between', 'center') }
    `
  }

  .container--logo {
    ${ utils.flex('row', 'flex-start', 'center') }
    ${
      media.greaterThan('850px')`
        flex: 10;
      `
    }

    .logo {
      width: 2em;
      margin-right: 1em;
    }

    .wording--logo {
      margin-top: .2em;
    }
  }

  .container--menu {
    flex: 16;
    ${ utils.flex('row', 'flex-end', 'center') }

    > a {
      margin-top: .1em;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .container--mobile-menu {
    color: white;
    font-size: 2.1em;
  }
`

const ContactButton = styled.button`
  width: 90px;
  height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 15px;
  color: white;
  cursor: pointer;
  border: 0;
  font-weight: bold;
  background: linear-gradient(to left, #1A2980, #26D9CE);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`

function LandingHeader(props: Props) {
  const [ burgerMenuOpen, setBurgerMenuOpen, ] = useState(false)
  const [ headerScroll, setHeaderScroll, ] = useState<number>(window.pageYOffset)

  const onBurgerMenuTriggerPress = () => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }

  useEffect(() => {
    const handleScroll = (e: any) => {
      const scroll = e.srcElement.scrollingElement.scrollTop
      setHeaderScroll(scroll)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const headerFixed = useMemo<boolean>(
    (): boolean => {
      if(headerScroll > 200) {
        return true
      } else if(headerScroll <= 200) {
        return false
      }

      return false
    },
    [ headerScroll, ]
  )

  const isSmall = useMediaQuery({ query: '(max-width: 849px)' })

  const onContactClick = () => {
    // @ts-ignore
    if(window.ChannelIO) {
      // @ts-ignore
      window.ChannelIO('openChat')

      ReactPixel.trackCustom('header_contact_us_button_click')
    }
  }

  return (
    <>
      <Menu
        isOpen={ burgerMenuOpen }
        customBurgerIcon={ false }
        styles={{
          bmCrossButton: {
            height: '30px',
            width: '30px',
            outline: 'none',
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'transparent',
          },
          bmCross: {
            width: '3px',
            height: '20px',
            background: '#bdc3c7',
          },
          bmMenuWrap: {
            position: 'fixed',
            height: '100%'
          },
          bmMenu: {
            background: '#373a47',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
            overflow: 'hidden',
          },
          bmMorphShape: {
            fill: '#373a47'
          },
          bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
          },
          // @ts-ignore
          bmItem: {
            marginBottom: '1em',
          },
          bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}>
        <A href='#about' className='menu-item' size={ 1.3 } sizeUnit='em' color='white' hoverColor={ colors.PRIMARY }>ABOUT</A>
        <A href='#service' className='menu-item' size={ 1.3 } sizeUnit='em' color='white' hoverColor={ colors.PRIMARY }>SERVICE</A>
        <A href='#portfolio' className='menu-item' size={ 1.3 } sizeUnit='em' color='white' hoverColor={ colors.PRIMARY }>PORTFOLIO</A>
        <A href='#team' className='menu-item' size={ 1.3 } sizeUnit='em' color='white' hoverColor={ colors.PRIMARY }>TEAM</A>
        <A className='menu-item' size={ 1.3 } sizeUnit='em' color='white' hoverColor={ colors.PRIMARY } onClick={ onContactClick }>
          상담요청
        </A>
      </Menu>
      <Transition in={ headerFixed } timeout={ 200 } appear={ headerFixed }>
        {
          (state => {
            return (
              <Header state={ state } fixed={ headerFixed }>
                <HeaderContent>
                  <div className='container--logo'>
                    <img
                      className='logo'
                      src={ require('assets/images/logo.png') }
                      alt='logo'
                    />
                    <Span size={ isSmall ? 1.5 : 1.4 } sizeUnit='em' color='white' weight='bold' className='wording--logo'>
                      D405 HOUSE
                    </Span>
                  </div>
                  {
                    !isSmall ? (
                      <nav className='container--menu'>
                        <A href='#about' size={ 1.2 } sizeUnit='em' color='white'>ABOUT</A>
                        <A href='#service' size={ 1.2 } sizeUnit='em' color='white'>SERVICE</A>
                        <A href='#portfolio' size={ 1.2 } sizeUnit='em' color='white'>PORTFOLIO</A>
                        <A href='#team' size={ 1.2 } sizeUnit='em' color='white'>TEAM</A>
                        <ContactButton onClick={ onContactClick }>
                          상담요청
                        </ContactButton>
                      </nav>
                    ) : (
                      <A onClick={ onBurgerMenuTriggerPress }>
                        <i className='fas fa-bars container--mobile-menu' />
                      </A>
                    )
                  }
                </HeaderContent>
              </Header>
            )
          })
        }
      </Transition>
    </>
  )
}

export default LandingHeader

import styled from 'styled-components'
import media from 'styled-media-query'
import Scrollspy from 'react-scrollspy'
import React, { useState, } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Transition, } from 'react-transition-group'

import { Container, H1, Span, P, Img, utils, } from 'styles'

import colors from 'globals/colors'

import { SectionCommonProps, } from '../types'

interface Props extends SectionCommonProps {
}

interface ExplanationWrapperProps {
  state: string
}

const Section2Container = styled.section`
  overflow-x: hidden;

  .second-section__why {
    background-color: #000000;
    padding: 5em 0;
    ${ utils.flex('row', 'center') }

    ${ Container } {
      ${ utils.flex('row', 'center', 'center') }
      ${
        media.lessThan('849px')`
          ${ utils.flex('column', 'center') }
        `
      }
    }


    .why__title-container {
      ${
        media.greaterThan('849px')`
          margin-right: 4em;
        `
      }

      ${ H1 } {
        line-height: 1.2em;
        text-align: center;
      }
    }

    .why__description-container {
      ${
        media.lessThan('849px')`
          margin-top: 3em;
        `
      }

      .description-container__title-container {
        margin-bottom: 1.8em;

        .why__quote-title-container {
          ${ utils.flex('row', 'flex-start', 'flex-start') }
          ${
            media.lessThan('849px')`
              ${ utils.flex('row', 'center', 'center') }
            `
          }
        }

        .quote-title-container__quote {
          margin-top: -0.3em;

          &.quote {
            margin-right: 0.6em;
          }

          &.unquote {
            margin-left: 0.6em;
          }
        }
      }
    }
  }
`

const Section2ExplanationContainer = styled.div`
  background-color: white;
  padding: 6em 0;
  width: 100%;
  ${ utils.flex('column', 'flex-start', 'center') }

  ${ Container } {
    ${ utils.flex('column') }
  }
`

const ExplanationWrapper = styled.div<ExplanationWrapperProps>`
  width: 100%;
  padding-bottom: 10em;
  align-self: center;
  transition: 0.5s ease;

  ${
    media.lessThan('1050px')`
      width: 95%;
    `
  }

  .head {
    margin-top: 1.6em;
  }

  .imgs {
    margin-top: 5em;

    &.row {
      ${ utils.flex('row') }

      &.first, &.second {
        img {
          &:first-child {
            ${
              media.greaterThan('1050px')`
                margin-right: 3.625em;
              `
            }
          }
        }
      }

      &.second {
        img {
          &:last-child {
            ${
              media.greaterThan('879px')`
                margin-top: 4em;
              `
            }
          }
        }
      }
    }

    img {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  &.left {
    ${
      props => {
        switch(props.state) {
          case 'entering':
            return `
              opacity: 0;
              transform: translateX(-150px);
            `
          case 'entered':
            return `
              opacity: 1;
              transform: translateX(0);
            `
          case 'exited':
            return `
              transform: translateX(-150px);
              opacity: 0;
            `
        }
      }
    }
  }

  &.right {
    ${ utils.flex('column', 'flex-start', 'flex-end') }

    ${
      media.lessThan('1050px')`
        ${ utils.flex('column', 'center', 'center') }
      `
    }

    ${
      props => {
        switch(props.state) {
          case 'entering':
            return `
              opacity: 0;
              transform: translateX(150px);
            `
          case 'entered':
            return `
              opacity: 1;
              transform: translateX(0);
            `
          case 'exited':
            return `
              transform: translateX(150px);
              opacity: 0;
            `
        }
      }
    }
  }
`

function Section2(props: Props) {
  const { focused, } = props

  const [ focusedExplanationElementId, setFocusedExplanationElementId, ] = useState('')

  const onScrollUpdate = (element: HTMLElement) => {
    if(element) {
      setFocusedExplanationElementId(element.id)
    }
  }

  const isSmall = useMediaQuery({ query: '(max-width: 1050px)' })

  return (
    <Section2Container { ... props }>
      <div className='second-section__why'>
        <Container>
          <div className='why__title-container'>
            <H1 size={ isSmall ? 4 : 5 } sizeUnit='em' color='white' weight='bold'>
              WHY
            </H1>
            <H1 size={ isSmall ? 3 : 3.8 } sizeUnit='em' color='white' weight='bold'>
              D405 HOUSE?
            </H1>
          </div>
          <div className='why__description-container'>
            <div className='description-container__title-container'>
              <div className='why__quote-title-container'>
                <Img
                  className='quote-title-container__quote quote'
                  width={ 0.8 }
                  sizeUnit='em'
                  alt='quote'
                  src={ require('./assets/images/quote.svg') }
                />
                <P size={ 1.2 } sizeUnit='em' color='white'>
                  무엇을 만들고 싶은지는 저희가 잘 알고 있습니다.
                </P>
                <Img
                  className='quote-title-container__quote unquote'
                  width={ 0.8 }
                  sizeUnit='em'
                  alt='unquote'
                  src={ require('./assets/images/unquote.svg') }
                />
              </div>
              <P size={ 1.2 } sizeUnit='em' color='white' textAlign='center' lineHeight={ 2 }>
                저희도 그랬으닌까요.
              </P>
            </div>
            <Span size={ 1.2 } sizeUnit='em' color='white' weight='light' lineHeight={ 1.6 }>
              다년간의 스타트업 참여 경험으로 다저진 ICT 서비스 개발 <br />
              역량과, 여러가지 노하우를 바탕으로 구상중인 서비스의<br />
              획을 그어드리겠습니다.
            </Span>
          </div>
        </Container>
      </div>
      <Scrollspy
        offset={ focused ? -400 : 200 }
        currentClassName='current-explanation-section'
        items={[ 'exp1', 'exp2', 'exp3', ]}
        onUpdate={ onScrollUpdate }
        componentTag='div'>
        <Section2ExplanationContainer>
          <Container>
            <Transition in={ focusedExplanationElementId === 'exp1' } timeout={ 0 }>
              {
                (state) => (
                  <ExplanationWrapper id='exp1' state={ state } className='left'>
                    <P size={ 2.4 } sizeUnit='em' color={ colors.PRIMARY } lineHeight={ 1.4 }>
                      기획이
                    </P>
                    <P size={ 2.4 } sizeUnit='em' color='#393939'>
                      완벽하지 않으셔도..
                    </P>
                    <P className='head' size={ 1.2 } sizeUnit='em' color='#696969'>
                      사업이 아직 아이디어 단계이신가요?
                    </P>
                    <P
                      size={ 1.2 }
                      sizeUnit='em'
                      color='#696969'
                      marginTop={ 0.8 }
                      lineHeight={ 1.5 }>
                      {
                        isSmall ? (
                          <>
                            기획이 완벽하지 않으셔도 괜찮습니다. 저희 D405 HOUSE 는
                            고객님께서 생각하고 계시는 아이디어나 초기 기획을 파악하고,
                            보완하여 아이디어를 현실화시킬 기획을 만들어드리겠습니다.
                          </>
                        ) : (
                          <>
                            기획이 완벽하지 않으셔도 괜찮습니다. 저희 D405 HOUSE 는<br />
                            고객님께서 생각하고 계시는 아이디어나 초기 기획을 파악하고,<br />
                            보완하여 아이디어를 현실화시킬 기획을 만들어드리겠습니다.
                          </>
                        )
                      }
                    </P>
                    <div className='imgs row first'>
                      <Img
                        alt='first_1'
                        src={ require('./assets/images/first_1.jpg') }
                        width={ isSmall ? '100%' : 36.5 }
                        height={ 19.8125 }
                        sizeUnit='em'
                        objectFit='cover'
                      />
                      {
                        !isSmall && (
                          <Img
                            alt='first_2'
                            src={ require('./assets/images/first_2.jpg') }
                            width={ 22.9375 }
                            height={ 31 }
                            sizeUnit='em'
                            objectFit='cover'
                          />
                        )
                      }
                    </div>
                  </ExplanationWrapper>
                )
              }
            </Transition>
            <Transition in={ focusedExplanationElementId === 'exp2' } timeout={ 200 }>
              {
                (state) => (
                  <ExplanationWrapper id='exp2' state={ state } className='right'>
                    <div>
                      <P size={ 2.4 } sizeUnit='em' color={ colors.PRIMARY } lineHeight={ 1.4 }>
                        디자인
                      </P>
                      <P size={ 2.4 } sizeUnit='em' color='#393939'>
                        컨셉이 구체적이지 않으셔도..
                      </P>
                      <P
                        className='head'
                        size={ 1.2 }
                        sizeUnit='em'
                        color='#696969'
                        lineHeight={ 1.5 }>
                        {
                          isSmall ? (
                            <>
                              ‘보기 좋은떡이 먹기도 좋다’ 라는 말처럼, ICT 서비스에서도
                              디자인은 전체 브랜딩 및 사용자의 경험에 중대한 요소로
                              작용합니다. 저희 D405 HOUSE가 서비스에 가장 적합한
                              디자인 컨셉 및 UI/UX 디자인을 통해 서비스의 품질을 한층 더 향상시켜 드리겠습니다.
                            </>
                          ) : (
                            <>
                              ‘보기 좋은떡이 먹기도 좋다’ 라는 말처럼, ICT 서비스에서도 디자인은<br />
                              전체 브랜딩 및 사용자의 경험에 중대한 요소로 작용합니다. 저희 D405 <br />
                              HOUSE가 서비스에 가장 적합한 디자인 컨셉 및 UI/UX 디자인을 통해<br />
                              서비스의 품질을 한층 더 향상시켜 드리겠습니다.
                            </>
                          )
                        }
                      </P>
                    </div>
                    <div className='imgs row second'>
                      <Img
                        alt='second_1'
                        src={ require('./assets/images/second_1.jpg') }
                        width={ isSmall ? '100%' : 30 }
                        height={ isSmall ? 19.8125 : 30 }
                        sizeUnit='em'
                        objectFit='cover'
                      />
                      {
                        !isSmall && (
                          <Img
                            alt='second_2'
                            src={ require('./assets/images/second_2.jpg') }
                            width={ 30 }
                            height={ 30 }
                            sizeUnit='em'
                            objectFit='cover'
                          />
                        )
                      }
                    </div>
                  </ExplanationWrapper>
                )
              }
            </Transition>
            <Transition in={ focusedExplanationElementId === 'exp3' } timeout={ 200 }>
              {
                (state) => (
                  <ExplanationWrapper id='exp3' state={ state } className='left'>
                    <P size={ 2.4 } sizeUnit='em' color={ colors.PRIMARY } lineHeight={ 1.4 }>
                      개발
                    </P>
                    <P size={ 2.4 } sizeUnit='em' color='#393939'>
                      지식이 전혀 없으셔도..
                    </P>
                    <P
                      className='head'
                      size={ 1.2 }
                      sizeUnit='em'
                      lineHeight={ 1.5 }
                      color='#696969'>
                      {
                        isSmall ? (
                          <>
                            어떤 기술스택을 선택하느냐는 추후 서비스의 확장이나,
                            유지보수에 굉장히 중요한 영향을 미칩니다. 저희 D405
                            HOUSE 에서 서비스 개발 경험이 풍부한 개발자가 확장성을
                            고려한 기술 스택을 제안 드릴것이며, 이를 바탕으로 완성도
                            있는 서비스를 개발해드릴것입니다.
                          </>
                        ) : (
                          <>
                            어떤 기술스택을 선택하느냐는 추후 서비스의 확장이나,<br />
                            유지보수에 굉장히 중요한 영향을 미칩니다. 저희 D405<br />
                            HOUSE 에서 서비스 개발 경험이 풍부한 개발자가 확장성을<br />
                            고려한 기술 스택을 제안 드릴것이며, 이를 바탕으로 완성도<br />
                            있는 서비스를 개발해드릴것입니다.
                          </>
                        )
                      }
                    </P>
                    <div className='imgs'>
                      <Img
                        alt='third_1'
                        src={ require('./assets/images/third_1.jpg') }
                        width='100%'
                        height={ isSmall ? 19.8125 : 27.0625 }
                        sizeUnit='em'
                        objectFit='cover'
                      />
                    </div>
                  </ExplanationWrapper>
                )
              }
            </Transition>
          </Container>
        </Section2ExplanationContainer>
      </Scrollspy>
    </Section2Container>
  )
}

export default Section2

import React from 'react'
import styled from 'styled-components'

import { A, P, Img, } from 'styles'

import { Portfolio, } from 'globals/types'

interface Props {
  portfolio: Portfolio
  className?: string
  onClick: (portfolio: Portfolio) => void
}

const PortfolioCardContainer = styled.div`
`

function PortfolioCard(props: Props) {
  const { portfolio, className, onClick } = props

  return (
    <PortfolioCardContainer className={ className }>
      <A onClick={ onClick }>
        {
          portfolio.thumbnailImage && (
            <Img
              src={ portfolio.thumbnailImage }
              width='100%'
              height='100%'
              objectFit='contain'
            />
          )
        }
      </A>
      <P
        size={ 1.1 }
        sizeUnit='em'
        color='#5C5C5C'
        textAlign='center'
        lineHeight={ 1.3 }>{ portfolio.name }</P>
      <P
        size={ 1 }
        sizeUnit='em'
        color='#B9B8B8'
        textAlign='center'
        lineHeight={ 1.2 }>
        { portfolio.description }
      </P>
    </PortfolioCardContainer>
  )
}

export default PortfolioCard

import Modal from 'react-modal'
import React, { useState, useImperativeHandle, } from 'react'

import { Portfolio, } from 'globals/types'

interface Props {
  ref: any
}

interface OpenModalParams {
  portfolio: Portfolio
}

function PortfolioModal(props: Props, ref: any) {
  const [ isVisible, setVisible, ] = useState(false)
  const [ portfolio, setPortfolio, ] = useState<Portfolio>()

  const open = (param: Portfolio) => {
    setVisible(true)
    setPortfolio(param)
  }

  const close = () => {
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal isOpen={ isVisible }>
    </Modal>
  )
}

export default React.forwardRef(PortfolioModal)
